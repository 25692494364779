import React from "react";
import { AuthContainerProps } from "./types";

export const AuthContainer: React.FC<AuthContainerProps> = ({
    anonymous,
    authenticated,
    isLoggedIn,
}) => {
    return <>{isLoggedIn ? authenticated : anonymous}</>;
};
