import { HttpClient } from "./HttpClient";

export interface ISubmitIssueDto {
    type: string;
    description: string;
}

export const IssueClient = {
    async submit(issue: ISubmitIssueDto) {
        const res = await HttpClient.post<ISubmitIssueDto>(
            "/api/issues",
            issue
        );

        if (!res.success) {
            throw new Error();
        }

        return res.data;
    },
};
