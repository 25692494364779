export interface IHttpResponse<T> {
    data: T;
    success: boolean;
}

export class HttpClient {
    public static async get<T>(url: string) {
        const res = await fetch(url);
        const data = (await res.json()) as T;
        const response: IHttpResponse<T> = {
            data,
            success: res.ok,
        };
        return response;
    }

    public static async post<T>(url: string, body: T) {
        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        const response: IHttpResponse<undefined> = {
            data: undefined,
            success: res.ok,
        };
        return response;
    }
}
