import { Box, Button, Header, Select, Text, TextArea } from "grommet";
import React, { useState } from "react";
import { IssueClient } from "../../client/IssueClient";
import { HeaderImage } from "./ReactForm.styles";

type IssueType = {
    name: string;
    placeholder: string;
};

const issueTypes: IssueType[] = [
    {
        name: "Site Outage",
        placeholder:
            "Example: I can't access the website. I have been unable to access it since 12pm.",
    },
    {
        name: "SSL Issue",
        placeholder:
            "Example: When I tried to view the website, Chrome showed me an SSL certificate warning and wouldn't let me access the site.",
    },
];

export const RequestForm = () => {
    const [issueType, setIssueType] = useState<IssueType>(issueTypes[0]);
    const [issueDescription, setIssueDescription] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const handleIssueTypeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setIssueType(
            issueTypes.find((o) => o.name === e.target.value) || issueTypes[0]
        );
    };

    const handleIssueDescriptionChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setIssueDescription(e.target.value);
    };

    const handleSubmit = async (e: React.MouseEvent) => {
        e.preventDefault();
        try {
            await IssueClient.submit({
                type: issueType.name,
                description: issueDescription,
            });
            setSuccess(true);
        } catch (e) {
            setError(true);
        }
    };

    return (
        <div>
            <Header background="brand">
                <Box
                    pad="small"
                    justify="start"
                    flex="grow"
                    direction="row"
                    align="center"
                >
                    <HeaderImage src="/circle-small.png" />
                    <Text size="large">Helpdesk</Text>
                </Box>
            </Header>

            <Box align="center" margin={{ top: "medium" }}>
                {error ? (
                    <Text>
                        Error submitting issue request. Please reload the page
                        and try again.
                    </Text>
                ) : success ? (
                    <Text>
                        Issue successfully submitted. We will be in touch with a
                        resolution soon.
                    </Text>
                ) : (
                    <Box width="medium">
                        <Select
                            placeholder="Select an issue type..."
                            margin={{ bottom: "small" }}
                            data-testid="issue-type"
                            onChange={handleIssueTypeSelect}
                            options={issueTypes.map((i) => i.name)}
                        />

                        <TextArea
                            data-testid="issue-description"
                            placeholder={issueType.placeholder}
                            onChange={handleIssueDescriptionChange}
                        ></TextArea>

                        <Button
                            primary
                            type="submit"
                            onClick={handleSubmit}
                            label="Submit"
                            margin={{ top: "small" }}
                        />
                    </Box>
                )}
            </Box>
        </div>
    );
};
