import { GlobalStyle } from "./App.styles";
import { AuthContainer } from "./components/AuthContainer/AuthContainer";
import { useAuth } from "./components/AuthContainer/useAuth";
import { RequestForm } from "./components/RequestForm/RequestForm";

function App() {
    const { isLoggedIn } = useAuth();
    // const isLoggedIn = true;

    return (
        <>
            <GlobalStyle />
            <AuthContainer
                isLoggedIn={isLoggedIn}
                anonymous={
                    <div>
                        <a href="/.auth/login/google">Login via Google</a>
                        <br />
                        <a href="/.auth/login/aad">Login via Microsoft</a>
                        <br />
                    </div>
                }
                authenticated={<RequestForm />}
            ></AuthContainer>
        </>
    );
}

export default App;
