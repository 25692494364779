import { useEffect, useState } from "react";
import { HttpClient } from "../../client/HttpClient";
import { UseAuth } from "./types";

type AuthState = {
    clientPrincipal: {
        userId: string;
        userRoles: string[];
        identityProvider: string;
        userDetails: string;
    } | null;
};

export const useAuth: UseAuth = () => {
    const [authState, setAuthState] = useState<AuthState>();

    const getAuthState = async () => {
        const { data } = await HttpClient.get<AuthState>("/.auth/me");
        setAuthState(data);
    };

    useEffect(() => {
        getAuthState();
    }, []);

    return {
        isLoggedIn: !!authState?.clientPrincipal,
    };
};
